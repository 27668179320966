import CHAT_URL from "./CHAT_URL";


export const fetchOpenAITopics = async (searchMessage) => {

    const resp = await fetch(CHAT_URL + "/suggested-topics", {
        method: "post",
        headers:{
            "Content-Type": "application/json"
        },
        body:JSON.stringify(searchMessage)
    })
    // console.log(resp)
    if (resp.ok) {
        const json = await resp.json();
        // const text = await resp.text();
        // return text
        return json
        // return resp
    }
}