import React, {useState, useEffect, useRef} from 'react';
import { fetchOpenAIResponse } from '../../util/fetchOpenAIResponse';
import { fetchOpenAITopics } from '../../util/fetchOpenAITopics';
import CHAT_URL from '../../util/CHAT_URL';

//images
import search from '../../images/icons/search.svg'
import filter from "../../images/icons/filter.svg"
import loader from "../../images/loader.svg"
import botIcon from "../../images/bot-icon-mini.svg"
import chevronSmall from "../../images/next-16.svg"
import searchSmall from "../../images/search-16.svg"
import closeIcon from "../../images/icons/close.svg"

let controller;

function Search(){

    const [searchTerm, setSearchTerm] = useState('')
    const [resultsShowing, setResultsShowing] = useState(false)
    const [noResults, setNoResults] = useState(false)
    const [recentSearches, setRecentSearches] = useState([])
    const [isAIResponding, setIsAIResponding] = useState(false)
    const [aIResponse, setAIResponse] = useState('')
    const [showFullResponse, setShowFullResponse] = useState(null)
    const [showFullResponseDisabled, setShowFullResponseDisabled] = useState(true)
    const [suggestedTopics, setSuggestedTopics] = useState([])

    const inputRef = useRef(null)
    const formRef = useRef(null)

    // useEffect(()=>{
    //     console.log(searchTerm)
    // },[searchTerm])

    const handleClearSearch = () => {
        setResultsShowing(false)
        setAIResponse('')
        setSuggestedTopics([])
        // setShowFullResponse(false)
        setShowFullResponseDisabled(true)
        setSuggestedTopics([])
    }


    const handleChangeSearch = (e) => {
        setSearchTerm(inputRef.current.value)
    }

    const handleSubmitSearch = (e) => {
        e.preventDefault()
        e.stopPropagation()
        handleClearSearch()
        // let inputVal = e.target.querySelector('input').value
        // console.log(e.target.querySelector('input'))
        // console.log(inputVal)
        let inputVal = inputRef.current.value
        if(inputVal != ''){
            if(inputVal === 'test'){
                setNoResults(false)
                setResultsShowing(true)
                let newArr = [...recentSearches]
                newArr.push(inputVal)
                setRecentSearches(newArr)
            }else{
                setResultsShowing(true)
                handleGetAIResponse()
            }
        } else{
            setResultsShowing(false)
            setNoResults(false)
        }
    }

    const handleGetAIResponse = async () => {

        //https://developer.mozilla.org/en-US/docs/Web/API/AbortController
        controller = new AbortController();
        const signal = controller.signal;

        if(!searchTerm) return;
        setIsAIResponding(true)
        // console.log(searchTerm)
        // console.log(inputRef.current.value)
        let newMessage = {data:{role: "user", content: searchTerm}}
        
        // fetchOpenAIResponse(newMessage)
        // .then((responseText)=>{
        //     setIsAIResponding(false)
        //     setAIResponse(responseText)
        // })

        fetchOpenAITopics({data:searchTerm})
        .then((response)=>{
            setSuggestedTopics(response)
        })

        const resp = await fetch(CHAT_URL, {
            method: "post",
            headers:{
                "Content-Type": "application/json"
            },
            body:JSON.stringify(newMessage),
            signal
        })
        //https://www.typeblock.co/blog/openai-stream
        try{
            const reader = resp.body
            .pipeThrough(new TextDecoderStream())
            .getReader()
            setIsAIResponding(false)
            while (true) {
            const { value, done } = await reader.read()
            if (signal.aborted) {
                break
            }
            if (done) {
                setShowFullResponseDisabled(false)
                break
            }
            if(value){
                setAIResponse((prev) => prev + value)
            }
            }
        }catch(error){
            console.log(error)
        }
    }

    return(
        <div className='search drawer-panel'>
            <div className='search-header'>
                <div className='search-container'>
                    <img src={search} alt='search icon'/>
                    <form ref={formRef} onSubmit={handleSubmitSearch}>
                        <input 
                            type='text'
                            placeholder='Search'
                            value={searchTerm}
                            onChange={handleChangeSearch}
                            ref={inputRef}
                        />
                        {
                            resultsShowing ? 
                            <button
                                type="button"
                                onClick={()=>{
                                    setSearchTerm('')
                                    handleClearSearch()
                                }}
                            >
                                <img src={closeIcon}/>
                            </button>
                            : null
                        }
                    </form>
                </div>
                <button 
                className='filter-button'
                disabled={!resultsShowing}
                >
                        <img src={filter}/>
                </button>
            </div>
            {
                resultsShowing ? 
                <div className={`toggle-tabs ai`}>
                    <button className={`ai active`}> <img src={botIcon}/><span>AI Overview</span></button>
                    <button className={`book`}>Book</button>
                    <button  className={`study`}>Study</button>
                </div>
             : null
            }
            
            {
                isAIResponding === true ? <>
                    <div className='spinner'>
                        <img src={loader}/>
                    </div>
                </>: null
            }
            {
                aIResponse.length > 0? 
                    <div className='ai-response'>
                        {
                            showFullResponse ?
                            <p>{aIResponse}</p>
                            : 
                            <p>{aIResponse.substring(0, 400)}...</p>
                        }
                        <button 
                            className='secondary'
                            onClick={()=>{setShowFullResponse(!showFullResponse)}}
                            disabled={showFullResponseDisabled}
                        >
                            {
                                showFullResponse ? 'Hide more' : 
                                showFullResponseDisabled ? 'Loading more' : 'Show more'
                            }
                        </button>
                        {
                            aIResponse ?
                            <>
                                {/* <h2>Learn more</h2>
                                <ul className='learn-more'>
                                    <li className='article'></li>
                                    <li className='article'></li>
                                    <li className='article'></li>
                                </ul> */}
                                <h2>Suggested reading</h2>
                                <ul>
                                    <li>
                                        <button>
                                            <span>Chapter</span>
                                            <img src={chevronSmall}/>
                                        </button>
                                    </li>
                                    <li>
                                        <button>
                                            <span>Chapter</span>
                                            <img src={chevronSmall}/>
                                        </button>
                                    </li>
                                </ul>
                                <h2>Similar topics</h2>
                                <ul>
                                    {
                                        suggestedTopics.length > 0 ? suggestedTopics.map((topic, index)=>{
                                            if(index < 5){
                                                return(
                                                    <li key={index}>
                                                        <button
                                                            onClick={(e)=>{
                                                                controller.abort();
                                                                setSearchTerm(topic)
                                                                setTimeout(()=>{
                                                                    formRef.current.dispatchEvent(
                                                                        new Event("submit", { cancelable: true, bubbles: true })
                                                                    )
                                                                },500)
                                                            }}
                                                        >
                                                            <span>{topic}</span>
                                                            <img src={searchSmall}/>
                                                        </button>
                                                    </li>
                                                )
                                            }
                                        })
                                        : null
                                    }
                                
                                </ul>
                            </>
                            : null
                        }
                        
                    </div>
                : null
            }
            {/* {
                resultsShowing === true ? <>
                    <p>{searchTerm}</p>
                </>: null
            } */}
            {
                    noResults === true ? <div className='no-results'>
                        <p className='title'>No results</p>
                        <p className='p'>Give it a shot with another search term.</p>
                        <button className="primary">Clear search</button>
                    </div> : null
            }
            {
                noResults === false && resultsShowing === false ? <div className='empty'>
                    <p className='title'>Looking for something?</p>
                    <p className='p'>If it's in your eText or notes, we'll track it down.</p>
                </div> : null
            }
        </div>
    )
}

export default Search